<!--司机签署协议页面-->
<template>
  <div class="home">
    <nav-bar :nav-info='navInfo'></nav-bar>
    <div class="container">
      <div class="pdfCon">
        <pdf v-for="v in pageTotalNum" :key="v" :page="v" :src="pdfUrl"></pdf>
      </div>
      <span>签名：</span>
      <div class="signCon">
        <vue-esign ref="esign" style="width: 100%" :height="400" :isCrop="isCrop" :line-width="lineWidth"
                   :line-color="lineColor" :bg-color="bgColor"></vue-esign>
      </div>
      <div class="buttonCon">
        <van-button @click="reset" style="width: 100%">重置</van-button>
        <van-button style="width: 100%" @click="submit">确认</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import {Toast, Button} from "vant";
import pdf from "vue-pdf-signature";
import CMapReaderFactory from "vue-pdf-signature/src/CMapReaderFactory"
import NavBar from '../../components/NavBar/NavBar.vue'

export default {
  name: "signAgreement",
  components: {pdf, NavBar},
  data() {
    return {
      navInfo: {
        title:'运力监控',
        left:true,
        rigth:true,
        path:'/home'
      },
      // api: '/tezhong/json',//正式地址
      // api: '',//测试
      waybillId: '',
      orderId: '',
      type: '',
      lng: '',
      lat: '',
      isNJ: '',

      isCrop: false,
      lineWidth: 6,
      lineColor: '#000',
      bgColor: '#fff',

      pageTotalNum: 1,
      // pdfUrl: 'https://www.so56.net/SO56TMS/hetong.pdf',
      pdfUrl:'/hetong.pdf'
    }
  },
  created() {
    this.waybillId = this.$route.query.waybillId;
    this.orderId = this.$route.query.orderId || undefined;
    this.type = this.$route.query.type;
    this.lng = this.$route.query.lng;
    this.lat = this.$route.query.lat;
    this.isNJ = this.$route.query.isNJ;
    this.pdfTask();
  },
  methods: {
    pdfTask() {
      this.pdfUrl = pdf.createLoadingTask({
        url: this.pdfUrl,
        cMapPacked: true,
        CMapReaderFactory,
      });
      this.pdfUrl.promise.then(pdf => {
        this.$nextTick(() => {
          this.pageTotalNum = pdf.numPages;
        })
      })
    },
    reset() {
      this.$refs.esign.reset();
    },
    back() {
      this.$router.back();
    },
    submit() {
      this.$refs.esign
          .generate() // 使用生成器调用把签字的图片转换成为base64图片格式
          .then(async (res) => {
                Toast.loading({
                  message: '确认中',
                  forbidClick: true,
                })
                let file = this.dataURLtoFile(res);
                let param = new FormData(), place;
                place = this.waybillId;
                param.append('waybillId', this.waybillId);
                param.append('file', file);
                let rst = await this.$Api.uploadPictures(place, param)
                console.log(rst.data)
                let signPic = rst.data.data[0].displayPicture
                let waybillId = this.waybillId;
                let orderId = this.orderId
                let result = await this.$Api.addConfirmationAgreement({
                  autograph: signPic,
                  waybillId: waybillId,
                  orderId: orderId
                })
                console.log(result,'33333')
                if (result.data.success) {
                  this.$router.push({
                    path: '/edit',
                    query: {
                      type: this.type,
                      lng: this.lng,
                      lat: this.lat,
                      waybillId: this.waybillId,
                      orderId: this.orderId,
                      mark: 1,
                    }
                  })
                }
              }
          )
          .catch((err) => {
            console.log(err)
            Toast('请输入签名后确认',);
          });
    },
    dataURLtoFile(url, filename) {
      let arr = url.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let bytes = atob(arr[1]); // 解码base64
      let n = bytes.length;
      let ia = new Uint8Array(n);
      while (n--) {
        ia[n] = bytes.charCodeAt(n);
      }
      return new File([ia], "easign.jpg", {type: mime});
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;
  font-size: 14px;
  overflow-x: hidden;

  .mint-header-out { //标题背景
    height: 50px;
    line-height: 50px;
    background-color: #06213E;
    width: 100%;
    position: fixed;
    top: 0;

    .bg-blue {
      color: #fff;
      font-size: 18px;

      img {
        width: 35%;
        height: auto;
        vertical-align: middle;
      }
    }
  }

  .container {
    position: fixed;
    top: 50px;
    height: calc(100% - 50px);
    overflow-x: hidden;
    overflow-y: auto;
    background: #f3f4f6;

    .pdfCon {
      width: 100%;
      margin-bottom: 10px;
    }

    .signCon {
      width: 100%;
      margin-top: 10px;
      background-color: #fff;
    }

    .buttonCon {
      //margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
  }

}
</style>